.grid_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  font-size: smaller;
}

.size {
  width: 90px;
  height: 90px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

a {
  text-decoration: none;
}

.heading {
  font-weight: bold;
  font-size: medium;
}

.description {
  font-size: small;
}


@media(max-width:1024px) {
  .heading {
    padding-top: 100px;
    padding-right: 20px;
  }
}

body {
  margin: 0;
  font-family: "Nunito Sans",sans-serif !important;
  text-align: center;
}

code {
  font-family: "Nunito Sans" !important;
}

/* Numeric keyword values */
.fw-100 {
  font-weight: 100 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 500 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.fs-48 {
  font-size: 43px !important;
}

.fs-25 {
  font-size: 25px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-20 {
  font-size: 20px !important;
  font-family: Arial,sans-serif;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-12 {
  font-size: 12px !important;
  font-family: Arial,sans-serif;
}

@media (max-width: 1024px) {

  .fs-48 {
      font-size: 40px !important;
  }

  .fs-25 {
      font-size: 22px !important;
  }

  .fs-20 {
      font-size: 18px !important;
  }

  .fs-18 {
      font-size: 20px !important;
  }

}

@media (max-width: 800px) {

  .fs-48 {
      font-size: 30px !important;
  }

  .fs-25 {
      font-size: 18px !important;
  }

  .fs-20 {
      font-size: 16px !important;
  }

  .fs-18 {
      font-size: 16px !important;
  }

}

@media (max-width: 500px) {

  .fs-48 {
      font-size: 24px !important;
  }

  .fs-25 {
      font-size: 16px !important;
  }

  .fs-20 {
      font-size: 15px !important;
  }

  .fs-18 {
      font-size: 14px !important;
  }

  .fs-14 {
      font-size: 12px !important;
  }
}



.mainColor{
  background: linear-gradient(159.52deg, #30cfd0 -41.05%, #330867 106.44%);
}

/* .rdt_Table .rdt_TableRow:hover {
  background: linear-gradient(159.52deg, #30cfd0 -41.05%, #330867 106.44%);;
  color: #fff !important;
} */
.kgfIBl{
  overflow: scroll !important;
  }
  .rdt_TableCell:first-child {
    border-left: 1px solid #dee2e6 !important;
  }
  
  .kgfIBl::-webkit-scrollbar { 
    width: 5px !important;
    height: 5px !important;
    /* display: none;   */
  }
  .kgfIBl::-webkit-scrollbar-thumb {
    background-color: #312577 !important;    /* color of the scroll thumb */
    border-radius: 20px !important;       /* roundness of the scroll thumb */
    padding: 0rem .5rem !important;
  }
  
  .kgfIBl::-webkit-scrollbar-thumb:horizontal{
    /* background: #000; */
    background-color: #312577 !important;    /* color of the scroll thumb */
    border-radius: 10px !important;
  }


  

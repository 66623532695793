.add{
    justify-content: end;
    display: flex;
}
.cms {
    line-height: 60px;
    padding: 20px;
    height: auto;
    width: auto;
}

.line {
    border-bottom: 1px solid rgb(206, 200, 200);
    font-weight: bold;
    /* color: ; */
}

.brd {
    border-bottom: 1px solid rgb(209, 206, 206);
}
.container2 {
    display: flex;
    padding-left: 0px;
    text-align: center;
}

main {
    width: 100%;
    padding: 20px;
}

.sidebar {
    top: -98px;
    height: 100%;
    /* background: #f8f9fa; */
    color: rgb(0, 0, 0);
    transition: all 0.5s;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.top_section {
    display: flex;
    align-items: center;
    padding-left: 1rem;
}

.logo {
    font-size: 30px;
}

.bars {
    display: flex;
    font-size: 25px;
    color: #281B75;
    justify-content: center;
    transition: all 0.5s;
}

.iconbars {
    display: flex;
    font-size: 25px;
    justify-content: center;
    padding-left: 1rem;
    margin-top: 39px;
    transition: all 0.5s;
}

.link {
    display: flex;
    color: rgb(0, 0, 0);
    padding: 10px 15px;
    width: 221px;
    transition: all 0.5s;
    text-decoration: none;
}

.link:hover {
    background: #281B75;
    color: rgb(255, 255, 255);
    transition: all 0.5s;
}

.linkb {
    width: 50px;
    display: flex;
    color: rgb(0, 0, 0);
    padding: 10px 15px;
    transition: all 0.5s;
    text-decoration: none;
}

/* .active {
    background: #281B75;
    color: rgb(255, 255, 255);
} */

.link_text {
    font-size: 20px;
    padding-left: 1rem;
}

.icon {
    font-size: 20px;
}

.navsidebar {
    top: 23px;
    /* background: #f8f9fa; */
    color: rgb(0, 0, 0);
    transition: all 0.5s;
    position: relative;
}

.sendCol {
    color: rgb(0, 0, 0);
    transition: all 0.5s;
}

@media (max-width: 600px) {
    .sendCol {
        color: rgb(0, 0, 0);
        width: fit-content;
        transition: all 0.5s;
    }

    .ps-sidebar-root {
        max-width: 28rem !important;
    }
}
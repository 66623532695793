.imageUploadSection{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 2rem;
    margin: 2rem 0rem;

}


.SectionTabMain :nth-child(1){
    color:black !important ;
}
.mainTab{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 5px;
    margin: 3rem 0rem;
}
.dropzone{
border: 1px dashed gray;
text-align: center;
padding: 2rem;
}



.loader {
    /* z-index: 10; */
    position: absolute;
    display: flex;
    justify-content: center;
    width: 80%;
    height: -webkit-fill-available;
    align-content: center;
    flex-wrap: wrap;
    backdrop-filter: blur(8px);
    text-align: center;
}

.doneLoader {
    display: none;
}

.loadingText {
    display: block;
}
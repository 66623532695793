#circle-avatar {
    display: grid;
    width: 50px;
    align-items: center;
    height: 50px;
    border-radius: 50%;
    background-color: azure;
    place-items: center;

}

span {
    /* font-size: 20px; */
    /* font-weight: bold; */
}

.card {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: none;
}

.list-group {
    cursor: pointer;
}

.pbtn {
    background: none;
    border: none;
}

.pbtn.active {
    border: none;
}

.navrow {
    margin-left: 0px;
    margin-right: 0px;
}
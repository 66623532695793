.formDiv {
    max-width: 20rem;
    border-radius: 1rem;
    padding: 1.5rem;
    margin: 179px auto;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.error {
    color: red;
    margin-top: 2px;
}
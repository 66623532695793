.pagination {
    position: absolute;
}
.page-link.active,
.active>.page-link {
    /* background-color: #281B75; */
    justify-content: center;
    text-align: center;
    color: #ffffff;
}

.page-link {
    color: #000;
    font-weight: 500;
    font-size: 16px;
    height: 45px;
    }

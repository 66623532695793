.button {
  color: #fff;
  background-color: #281b75;
  border-radius: 4px;
  font-size: 20px;
  font-weight: bold;
  border: none;
  max-height: max-content;
  max-width: fit-content;
  outline: none;
  margin: 1rem 2rem;
  border: 1px solid #fff;
  align-items: center;
  text-transform: capitalize;
  letter-spacing: 0.34px;
  padding: 10px;
  transition: 0.4s all;
}

.button:hover {
  background-color: #ffffff;
  color: #ffffff;
  font-weight: bold;
  border: 1px solid #281b75;
  transition: 0.3s all;
}
/* 
.button:focus {
  background-color: #372d75;
} */

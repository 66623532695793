.grid_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  font-size: smaller;
}

.size {
  max-width: 200px;
  max-height: 100px;
}

a {
  text-decoration: none;
}

.title {
  font-weight: bold;
  padding-top: 30px;
  text-align: center;
  font-size: medium;
}

.description {
  font-size: small;
}

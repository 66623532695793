
.edit {
    margin-left: 23rem !important;
    justify-content: end;
    display: flex;
}

.delete {
    margin-left: 5px;
    justify-content: center;
}

.date {
    justify-content: end;
    display: flex;
}

.update {
    justify-content: end;
    margin-right: 2px;
}
.boxcss {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.logoCol {
  display: -ms-inline-grid;
}

.flightcomp {
  background-color: #f5f5f5;
  border-radius: 7px;
  padding: 10px;
  margin: 0.6rem 0rem;
  font-size: small;
}

.pngerCard {
  padding: 10px;
  width: 20rem;
  border-radius: 7px;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  font-size: small;
}
.modalBody{
height: 100px;
overflow-y: scroll;
}
.modalBody::-webkit-scrollbar { 
    /* display: none;   */
    /* width: 10px !important; */
}
.feesStyle{
font-size: 14px;
}
.secoreHeading{
font-weight: 600;
}
.details {
  font-weight: 900;
  /* text-align: center; */
  font-size: 16px;
}

.userdetails {
  justify-content: center;
  font-family: "Nunito Sans";
  display: flex;
}

.Card {
  margin: 10px;
  width: 40rem;
  padding: 20px;
  justify-content: center;
  display: flex;
}
.tableheadeing{
 background-color: #281b75 !important;
}

.abortedCSS{
color: black !important;
font-weight: 600;
}